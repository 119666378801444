<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add New" }} Weight Record</v-card-title
      >

      <v-divider></v-divider>

      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="weight-form">
          <v-text-field
            label="Date"
            v-model="fields.date"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('date')"
            :error-messages="errors['date']"
          ></v-text-field>

          <v-text-field
            label="Weight (kg)"
            v-model="fields.value"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('value')"
            :error-messages="errors['value']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="weight-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        value: null,
        date: null,
      },
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  methods: {
    openForm: function (weight = null) {
      if (weight !== null) {
        this.weight = weight;
        this.fields.value = weight.value;
        this.fields.date = weight.date;

        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        dogId: this.$route.params.dogId,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.weightId = this.weight.id;
      }

      this.$store
        .dispatch("lhl/dogs/saveWeight", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields.value = null;
    },
  },
};
</script>
